const playerEvent = async ({ presentationId }) => {
  const params = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      eventId: process.env.EVENT_ID,
      presentationId: presentationId,
    }),
  }

  fetch(process.env.API_URL + "/player-event", params)
}

export { playerEvent }
